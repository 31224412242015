



import { Vue, Component } from 'vue-property-decorator'
import InfoIndex from '@/components/info/index.vue'

@Component({ components: { InfoIndex } })
export default class ViewInfo extends Vue {
}
