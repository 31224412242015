






















import { Component, Vue } from 'vue-property-decorator'

  @Component
export default class InfoIndex extends Vue {
  items = [
    {
      icon: 'mdi-language-typescript',
      title: 'Development Language',
      subtitle: 'typescript v4',
      subheader: 'Common'
    },
    {
      icon: 'mdi-server',
      title: 'server',
      subtitle: 'CentOS 7 on KT Cloud',
      subheader: 'Receive system'
    },
    {
      icon: 'mdi-nodejs',
      title: 'system',
      subtitle: 'nodejs v12'
    },
    {
      icon: 'mdi-firebase',
      title: 'Authentication',
      subtitle: 'Firebase authentication',
      subheader: 'Web Architecture'
    },
    {
      icon: 'mdi-firebase',
      title: 'Database',
      subtitle: 'Firebase firestore, realtime database'
    },
    {
      icon: 'mdi-firebase',
      title: 'Web server',
      subtitle: 'Firebase hosting'
    },
    {
      icon: 'mdi-firebase',
      title: 'File server',
      subtitle: 'Firebase storage'
    },
    {
      icon: 'mdi-firebase',
      title: 'Back-end',
      subtitle: 'Firebase functions'
    },
    {
      icon: 'mdi-vuejs',
      title: 'Front-end',
      subtitle: 'vuejs v2'
    },
    {
      icon: 'mdi-vuetify',
      title: 'CSS framework',
      subtitle: 'vuetifyjs v2'
    },
    {
      icon: 'mdi-magnify',
      title: 'Search engine',
      subtitle: 'algolia'
    }

  ]

  get xs () { return this.$vuetify.breakpoint.xs }
}
